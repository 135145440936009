<template>
  <div :class="$style.container">
    <div :class="$style.group">
      <ui-button
        fill
        theme="ui"
        size="medium"
        :class="$style.btn"
        icon="chevron-left-thick"
        :to="backTo"
      >
        {{ t('Back') }}
      </ui-button>
    </div>
    <div :class="$style.group">
      <ui-button
        fill
        theme="ui"
        size="medium"
        :class="$style.btn"
        to="/fantasy/collection"
      >
        {{ t('Collection') }}
      </ui-button>
      <ui-button
        fill
        theme="ui"
        size="medium"
        :class="$style.btn"
        to="/fantasy/shop"
      >
        {{ t('Shop') }}
      </ui-button>
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Back": "Вернуться",
    "Shop": "Магазин карточек",
    "Collection": "Моя коллекция"
  }
}
</i18n>

<script>
import UiButton from '~/components/ui/button'

export default {
  name: 'FantasyCommonNav',
  components: {
    UiButton
  },
  computed: {
    backTo() {
      return '/fantasy'
      // if (this.$router.history._startLocation === this.$route.fullPath) {
      //   this.$router.push(
      //     this.$normalizePath(this.defaultPath)
      //   )
      // } else {
      //   this.$router.back()
      // }
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<style lang="scss" module>
.container {
  margin-bottom: 4em;
  display: flex;
  align-items: center;
  @include down(sm) {
    display: block;
  }
}
.group {
  display: flex;
  align-items: center;
  @include down(sm) {
    margin-left: -1.6em !important;
    margin-right: -1.6em;
    padding-left: 1.6em;
    padding-right: 1.6em;
    &:nth-child(1) {
      padding-bottom: 2em;
    }
    &:nth-child(2) {
      padding-top: 2em;
    }
  }
  & + & {
    margin-left: 1.2em;
    @include down(sm) {
      border-top: 1px solid #1a202f;
    }
  }
}
.btn {
  padding: 1.2em;
  font-weight: 400 !important;
}
.btn + .btn {
  margin-left: 1.2em;
}
</style>
